import React, { FC, ReactElement } from 'react';

import './BlogAuthorDetails.scss';

interface Props {
  readTime: string;
  publishData: string;
}

const BlogAuthorDetails = (props: Props): ReactElement => {
  
  const { readTime } = props
  const { publishData } = props

  const getUKDate = dateString => {
    const splitDate = dateString.split('-');
    return [splitDate[2], splitDate[1], splitDate[0]].join('-');
  };
  
  return (
    <div id="author" className="authorInfo">
    <img
      className="authorInfoImg"
      src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/photos/blog/author-placeholder-01.png"
      alt="Author of the article"
    />
    <div className="authorDetails">
      <p className="authorDetailsName">By Saoirse McClure Fisher</p>
      <p className="authorDetailsText">
        <span>
          <img
            className="authorDetailsImg"
            src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/icons/16976-assets_icon-calendar-red.svg"
            alt="calendar"
          />
          &nbsp;{getUKDate(publishData)}&nbsp;
        </span>
        <span>
          <img
            className="authorDetailsImg"
            src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/icons/16976-assets_icon-tag-red.svg"
            alt="tag"
          />
          &nbsp;MAINTENANCE&nbsp;
        </span>
        <span>
          <img
            className="authorDetailsImg"
            src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/icons/16976-assets_icon-clock-red.svg"
            alt="clock"
          />
          &nbsp;{readTime}
        </span>
      </p>
    </div>
  </div>
  )
};




export default BlogAuthorDetails;
