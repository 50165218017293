import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import Layout from '@/components/Layout/Layout';
import GeneralHeroSec from '@motokiki/shared/generalHeroSec';
import SearchWidgetContainer from '../../components/SearchWidgetContainer';
import BlogAuthorDetails from '@/components/BlogAuthorDetails';
import TextBlock from '../../components/GeneralTextBlock';

import './BlogPost.scss';
import SEO from '@/components/SEO/Seo';

interface Props {
  data: {
    strapi: {
      article: {
        IntroText: string;
        Title: string;
        blogPost: string;
        pageUrl: string;
        readTime: string;
        publishData: string;
        Seo: {
          description: string;
          title: string;
        };
        heroSection: {
          activeLozenge: string;
          className: string;
          displayIcon: string;
          headerPrefix: string;
          headerText: string;
          iconImg: {
            url: string;
          };
          lozengeText: string;
          mainImg: {
            url: string;
          };
        };
      };
    };
  };
}

const BlogPostSideBar = ({ data }: Props): ReactElement => {
  const { blogPost, heroSection, Seo, readTime, publishData } = data.strapi.article;

  return (
    <>
    <SEO
      title={Seo.title}
      description={Seo.description}      
      />
      <Layout>
        <GeneralHeroSec data={heroSection} />
        <section className="postSectionMain">
          <div className="postSectionWrapper">
            <BlogAuthorDetails readTime={readTime} publishData={publishData}/>

            <TextBlock blogData={blogPost} />

            <div id="socialShare" className="socialShare">
              <p className="bold">Share this article:</p>
              <a href="https://www.facebook.com/Motokikiuk/" target="_blank" rel="noreferrer">
                <img
                  className="socialImg"
                  width="35"
                  src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/social/16976-assets_icon-fb-darkGrey.svg"
                  alt="facebook"
                />
              </a>
              <a href="https://twitter.com/Motokikiuk" target="_blank" rel="noreferrer">
                <img
                  className="socialImg"
                  width="35"
                  src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/social/16976-assets_icon-tw-darkGrey.svg"
                  alt="twitter"
                />
              </a>
              <a href="https://www.instagram.com/motokikiuk/" target="_blank" rel="noreferrer">
                <img
                  className="socialImg"
                  width="35"
                  src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/social/16976-assets_icon-insta-darkGrey.svg"
                  alt="instagram"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/motokiki"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="socialImg"
                  width="35"
                  src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/social/16976-assets_icon-li-darkGrey.svg"
                  alt="linkedin"
                />
              </a>
            </div>
          </div>

          <div className="btnPosition">
            <a className="btn" type="button" href="/blogs">
              Back to articles
            </a>
          </div>
        </section>

        <SearchWidgetContainer />
      </Layout>
    </>
  );
};

export const BlogPages = graphql`
  query($id: ID!) {
    strapi {
      article(id: $id, publicationState: PREVIEW) {
        IntroText
        Title
        blogPost
        pageUrl
        readTime
        publishData
        Seo {
          description
          title
        }
        heroSection {
          activeLozenge
          className
          displayIcon
          headerPrefix
          headerText
          iconImg {
            url
          }
          lozengeText
          mainImg {
            url
          }
        }
      }
    }
  }
`;

export default BlogPostSideBar;
